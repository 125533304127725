import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
	cursor: pointer;
	transform: rotate(${(props) => (props.isOpen ? "-180deg" : "0")});
`;

export const DropdownIcon = ({ isOpen }) => (
	<Icon
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		isOpen={isOpen}
	>
		<path d="M2 6L10 14L18 6" stroke="black" />
	</Icon>
);
